<template>
  <div>
    <b-row>
      <b-col lg="12">
        <div
          class="card card-custom gutter-b card-stretch card-shadowless bg-light"
        >
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Usage summary
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <!--begin::Table-->

            <b-row align-v="end">
              <b-col lg="5">
                <label for="fromDatePicker">From</label>
                <b-form-datepicker
                  v-model="fromDate"
                  id="fromDatePicker"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>

              <b-col lg="5">
                <label for="toDatePicker">To</label>
                <b-form-datepicker
                  v-model="toDate"
                  id="toDatePicker"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>

              <b-col lg="2">
                <b-button
                  id="fetchButton"
                  class="btn btn-primary fetch-button mb-2"
                  @click="updateData"
                  block
                  variant="primary"
                >
                  Fetch
                </b-button>
              </b-col>
            </b-row>

            <!-- <table class="table fixed-table">
              <thead>
                <tr>
                  <template v-for="(heading, i) in headings">
                    <th v-bind:key="i" scope="col">{{ heading }}</th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="(summary, i) in filteredData">
                  <tr v-bind:key="i">
                    <td class="table-col">{{ summary.user }}</td>
                    <td class="table-col">{{ summary.sentMessages }}</td>
                    <td class="table-col">{{ summary.smsCount }}</td>
                    <td class="table-col">{{ summary.messageFails }}</td>
                  </tr>
                </template>
              </tbody>

              <b-pagination
                v-model="currentPage"
                :per-page="perpage"
                :total-rows="totalRows"
                @change="pageChange($event)"
                :hide-ellipsis="true"
              ></b-pagination>
            </table> -->
            <!--end::Table-->

            <b-table responsive :fields="fields" :items="usageSummaries">
            </b-table>

            <b-row class="mb-5">
              <b-col class="text-muted">
                {{ userCountLabel }} users displayed
              </b-col>
            </b-row>
          </div>
          <!--end::Body-->
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style></style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      fromDate: this.getLastWeek(),
      toDate: this.getToday(),
      fields: [
        { key: "userName", label: "User", formatter: "trimEmail" },
        { key: "successfulCount", label: "Successful messages" },
        { key: "smsCount", label: "SMS count" },
        { key: "failedCount", label: "Failed messages" },
      ],
      usageSummaries: [],
    };
  },

  components: {},

  computed: {
    picture() {
      return (
        process.env.BASE_URL + "media/svg/avatars/avatar-single-orange.svg"
      );
    },
    totalRows() {
      return this.usageSummaries.length;
    },
    requestUrl() {
      let adjustedToDate = new Date(this.toDate + " 00:00");
      adjustedToDate.setDate(adjustedToDate.getDate() + 1);
      let queryString = "sentMessages/summary?";
      queryString += `&From=${new Date(this.fromDate + " 00:00")
        .toISOString()
        .replace("Z", "")}`;
      queryString += `&To=${adjustedToDate.toISOString().replace("Z", "")}`;
      return queryString;
    },
    userCountLabel() {
      let label;
      if (this.usageSummaries.length > 0) {
        label = this.usageSummaries.length;
      } else {
        label = "No";
      }
      return label;
    },
  },

  methods: {
    getUsageSummaries() {
      ApiService.query(this.requestUrl).then((response) => {
        this.usageSummaries = response.data.sort((a, b) =>
          a.smsCount < b.smsCount ? 1 : -1
        );
      });
    },
    getLastWeek() {
      let today = new Date();
      let lastWeek = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        today.getDate() - 7
      );
      return `${lastWeek.getFullYear()}-${lastWeek.getMonth()}-${lastWeek.getDate()}`;
    },
    getToday() {
      let today = new Date();

      return `${today.getFullYear()}-${today.getMonth() +
        1}-${today.getDate()}`;
    },
    updateData() {
      this.getUsageSummaries();
    },
    trimEmail(value) {
      return value.substring(0, value.indexOf("@"));
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" },
    ]);

    this.getUsageSummaries();
  },
};
</script>
